@import "./../styles/variables.scss";

.footer{
    padding: 1.2rem 2rem 1rem 2rem;
    background-color: #062c3f;
    color: white;
    font-family: "Lato","sans-serif";
    @include breakpoint(md){
        padding: 1.2rem 7rem 1.2rem 7rem;
    }

}

.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    
}

    
.chipleki{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding-bottom: 2rem;
    width: 100%;

    @include breakpoint(md){
        flex-direction: row;
        width: auto;
    }

    &__social{
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        gap: 1.5rem;
        margin-bottom: 2rem;
        width: 100%;
        @include breakpoint(md){
            flex-direction: column;
            margin-bottom: 0;
            width: 25%;
        }
        a{
            color: white;
        }

    }
    &__info{
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 1rem;
        @include breakpoint(md){
            width: 75%;
        }

        &__logo{
            img{
                width: 100%;
                max-width: 250px;
            }
        }

        &__text{
            h2{
                font-weight: 600;
                font-size: 18px;
            }
    
            p{
                font-size: 15px;
            }
        }
    }
}


.uLogo{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
    
    @include breakpoint(md){
        width: auto;
    }

    img{
        max-height: 70px;
        @include breakpoint(md){
            height: 70px;
        }
    }
}