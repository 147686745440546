@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

$ubuntu-font: "Ubuntu", sans-serif;

$bg: rgb(255, 255, 255);
$bg2: rgb(0, 0, 0);
$text: rgb(15, 15, 15);
$text2: rgb(240, 240, 240);
$primary: rgb(22, 210, 247);
$white: white;
$yellow: #ffe01b;

$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 32px;

$header-height: 92px;

$sm: 600px;
$md: 900px;
$lg: 64em;
$xl: 75em;

@mixin breakpoint($point) {
    @if $point == md {
        @media (min-width: $md) {
            @content;
        }
    }
    @if $point == lg {
        @media (min-width: $lg) {
            @content;
        }
    }

    @if $point == sm {
        @media (min-width: $sm) {
            @content;
        }
    }

    @if $point == logo {
        @media (min-width: 450px ) and (max-width: 900px) {
            @content;
        }
    }
}