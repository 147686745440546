@import "./../styles/variables.scss";


.section{
    padding: 1.2rem 30px;
    background-color: #F9F9F9;
    &__container{
        display: flex;
        flex-wrap: wrap;
        &__description{
            margin-top: 2rem;
            font-family: "Lato","sans-serif";

            display: flex;
            align-self: center;
            flex-direction: column;
            gap: 1rem;

            @include breakpoint(md){
                width: 50%;
                margin-top: 0;
                padding: 0 5%;
                gap: 2rem;
            }

            &__title{
                
                color: black;
                margin-bottom: 1rem;
                @include breakpoint(md){
                    width: 75%;
                    font-size: 52px;
                    display: flex;
                    text-align: start;
                    margin-bottom: 0;
                }
                font-size: 38px;
                font-weight: 500;
            }

            &__info{
                color: gray;
                text-align: justify;
                font-size: 20px;
                margin-bottom: 1rem;
                @include breakpoint(md){
                    font-size: 24px;
                    margin-bottom: 0;
                }
            }
        }

        &__video{
            width: 100%;
            padding: 1.5rem 0;
            height: 400px;

            @include breakpoint(md){
                width: 50%;
                height: 500px;
            }

        }

    }
}

hr {
    content: '';
    display: inline-block;
    height: 2px;
    width: 5rem;
    border: 0;
    margin-left: 0;
    border-top: 3px solid #DAAB76;
    text-align: center;
}