@import "./../styles/variables.scss";

.container{
    padding: 3rem 3rem;
    background-color: white;

    &__section{
        @include breakpoint(md){
            padding: 2rem 4rem;
        }
        &__title{
                
            color: black;
            margin-bottom: 1rem;
            @include breakpoint(md){
                width: 75%;
                font-size: 52px;
                display: flex;
                font-family: "Lato","sans-serif";
                text-align: start;
                margin-bottom: 0;
                margin-bottom: 1.6rem;
            }
            font-size: 28px;
            font-weight: 500;
        }
    }

}


.wrapper{
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;

    &__card{
        height: 500px;
        margin: 50px;
        box-shadow: 5px 5px 20px black;
        overflow: hidden;

        img{
            height: 500px;
            width: 350px;
            border-radius: 3px;
        }
        &__intro{
            height: 200px;
            width: 350px;
            padding: 6px;
            margin-bottom: 240px;
            position: absolute;
            background: rgb(27,27,27,0.5);
            color: white;

            h1{
                margin: 10px;
                font-size: 40px;
            }
            p{
                font-size: 20px;
                margin: 20px;
            }
        }


        
    }
}


.wrapper{
    justify-content: center;
    display: flex;
    margin: 0 auto;
    padding: 2rem 4rem;
  }
  .border:hover {
    border: 1px solid #fff;
  }
  .card {
    height: 350px;
    width: 350px;
    min-width: 300px;
    background: #808080;
    border-radius: 10px;
    transition: background 0.8s;
    overflow: hidden;
    background: #000;
    box-shadow: 0 70px 63px -60px #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    a{
        color: white;
    }
    &:hover{
        h2{
            opacity: 1;
        }

        h3{
            opacity: 1;
        }

        .icons{
            opacity: 1;
        }

    }
    img{
        background-size: cover;
        height: 100%;   
        position: absolute;
    }
    h2 {
      font-family: "Lato","sans-serif";
        color: #fff;
        margin: 20px;
        opacity: 0;
        font-size: 30px;
        position: absolute;
        bottom: 40px;
        left: 15%;
        transition: opacity 0.2s;
        font-weight: 550;
      }

      h3 {
        font-family: "Lato","sans-serif";
        color: #fff;
        margin: 20px;
        opacity: 0;
        font-size: 18px;
        position: absolute;
        bottom: 0;
        left: 15%;
        font-size: 20px;
        transition: opacity 0.2s;
      }

  }

  
  .icons {
    position: absolute;
    fill: #fff;
    color: #fff;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column; 
    justify-content: space-around;
    opacity: 0;
    padding-right: 1rem;
    gap:1rem;
    transition: opacity 0.2s ease-in;
    padding-top: 1rem;
  } 


  .card1{
    background-image: url("../imgs/Vicente.JPG");
    background-size: cover;
    background-position: center;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.25s ease-in-out;
  }

  .card2{
    background-image: url("../imgs/Javier.JPG");
    background-size: cover;
    background-position: center;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.25s ease-in-out;
  }
  .card3{
    background-image: url("../imgs/Axel.JPG");
    background-size: cover;
    background-position: center;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.25s ease-in-out;
  }
  .card4{
    background-image: url("../imgs/Joaquin.JPG");
    background-size: cover;
    background-position: center;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.25s ease-in-out;
  }

  .card5{
    background-image: url("../imgs/Ariel.JPG");
    background-size: cover;
    background-position: center;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.25s ease-in-out;
  }
  .card6{
    background-image: url("../imgs/Yi.JPG");
    background-size: cover;
    background-position: center;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.25s ease-in-out;
  }

  .card6:hover{
    background-color: rgba(0,0,0,.4);   
    background-size: cover;
    background-position: center;
   
    
  }
  .card6:before {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: inherit;
    content: ' ';
  }

  .card5:hover{
    background-color: rgba(0,0,0,.4);   
    background-size: cover;
    background-position: center;
   
    
  }
  .card5:before {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: inherit;
    content: ' ';
  }

  .card4:hover{
    background-color: rgba(0,0,0,.4);   
    background-size: cover;
    background-position: center;
   
    
  }
  .card4:before {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: inherit;
    content: ' ';
  }

  .card3:hover{
    background-color: rgba(0,0,0,.4);   
    background-size: cover;
    background-position: center;
   
    
  }
  .card3:before {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: inherit;
    content: ' ';
  }

  .card2:hover{
    background-color: rgba(0,0,0,.4);   
    background-size: cover;
    background-position: center;
   
    
  }
  .card2:before {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: inherit;
    content: ' ';
  }

  .card:hover{
    background-color: rgba(0,0,0,.4);   
    background-size: cover;
    background-position: center;
   
    
  }
  .card:before {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: inherit;
    content: ' ';
  }

