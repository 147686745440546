@import "./../styles/variables.scss";

.functionalities{
    font-family: "Lato","sans-serif";

    padding: 3rem 2rem;
    @include breakpoint(md){
        padding:5rem 7rem 5rem 7rem;
    }

    &__group{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 4rem;

        &__h2{
            font-size: 38px;
            font-weight: 500;
            @include breakpoint(md){
                font-size: 52px;
                
                
            }
    
        }
        &__p{
            font-size: 20px;
            color: gray;
            width: 100%;
            text-align: center;
            line-height: 1.6rem;
            
            @include breakpoint(md){
                width: 40%;
                font-size: 24px;
            }
        }
    
        &__hr{
                content: '';
                display: inline-block;
                height: 2px;
                width: 5rem;
                border: 0;
                margin: auto;
                border-top: 3px solid #DAAB76;
                text-align: center;
            }
    }

   

    
}

.container{
    &__section{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 5rem;
        &__icon{
            width: 100%;
            min-width: 350px;
            @include breakpoint(md){
                flex: 1
            }
        }

        &__description{
            display: flex;
            flex-direction: column;
            gap: 3rem;
            &__row{
                display: flex;
                gap: 2rem;
                &__icon{
                    margin: auto;
                    img{
                        
                        width: 50px;
                        height: 50px;
                    }
                }
                &__title{
                    font-size: 24px;
                    margin-bottom: .7rem;


                }

                &__info{
                    font-size: 20px;
                    color: gray;
                }
            }

            @include breakpoint(md){
               flex :1;
            }
        }
    }
}

   



.terminal{
    width: 60%;
}