@import "./../styles/variables.scss";


.main{
    background:  linear-gradient(rgba(0, 0, 0, 0.3), rgba(36, 28, 28, 0.9)), url('../imgs/background7.jpg');
    background-size: cover;
    height: 60vh;
    color: white;


    @include breakpoint(md){
        background-repeat: no-repeat;
        padding-left: 3rem;
        background-size: 100%;
        background-position: 60% 70%;
        height:100vh;
        width: 100%;
        &__title{
            width: 60%;
        }
    }

    overflow:hidden;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    width: 100%;
    gap: 0;

    &__title{
        font-family: "Lato","sans-serif";
        @include breakpoint(md){
            margin-top: 3.5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.5rem;
        }

        margin: 0 0;
        padding: 0 2rem;
        
        

        h1{
            @include breakpoint(md){
                font-size: 52px;
                font-weight: 700;
                margin-bottom: 30px;
                

            }
            text-align: start;
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 2.3rem;
        
        }

        h2{
            @include breakpoint(md){
                font-size: 24px;
                margin-bottom: 30px;

            }
            text-align: justify;
            font-size: 20px;
            margin-bottom: 2.3rem;
            font-family: "Lato","sans-serif";
        }

        button{
            @include breakpoint(md){
                font-size: 20px;
            }

            border: none;
            color: #DAAB76;
            padding: 0.8rem 1.3rem;
            font-size: 16px;
            border-radius: 5px;
            background-color: transparent;
            border: 2px solid #DAAB76;
            cursor: pointer;
            
            transition: all 1s ease;

            &:hover{
                background-color: #DAAB76;
                color: white;
                
            }
            
        }
    }
}

