@import "./../styles/variables.scss";

.container{
    padding: 3rem 1rem;
    background-color: #F9F9F9;
    font-family: "Lato","sans-serif";
    &__section{
        margin-top: $header-height;
        margin-bottom: 2rem;
        @include breakpoint(md){
            padding: 2rem 4rem 0 4rem;
            margin-bottom: 0;
        }
        &__title{
                
            color: black;
            margin-bottom: 1rem;
            display: flex;
            text-align: start;
            align-items: center;
            justify-content: center;
            @include breakpoint(md){
                font-size: 52px;
                margin-bottom: 0;
                margin-bottom: 1.6rem;
            }
            font-size: 38px;
            font-weight: 500;
        }
        hr {
            // center
            display: block;
            margin: auto;
        }
    }

    &__team{
        margin-top: $header-height;
        @include breakpoint(md){
            padding: 2rem 4rem;
        }
    }

    &__info{
        @include breakpoint(md){
            padding: 2rem 4rem;
        }    
        
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &__aboutus{
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            
            @include breakpoint(md){
                padding: 2rem;
                gap: 3rem;
            }

            &__logo{
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                
                @include breakpoint(md){
                    justify-content: flex-end;
                }
                img{
                    width: 50%;
                    height: auto;
                    object-fit: contain;
                    min-width: 300px;
                }
            }

            &__text{
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: flex-start;
                padding: 1rem;
                p{
                    font-size: 1rem;
                    font-weight: 400;
                    // line-height: 1.5;
                    color: #4F4F4F;

                    @include breakpoint(md){
                        font-size: 1.5rem;
                    }
                }
            }
        }

        &__body{
            display: flex;
            gap: 2rem;
            flex-wrap: wrap;
                
            &__card{
                background: rgb(255, 255, 255);
                flex: 1;
                min-width: 300px;
                transition: all 0.4s ease-in;
                border-radius: 10px;
                &:hover{
                        transform: translateY(-10px) scale(1.005) translateZ(0);
                        box-shadow: 0 24px 36px rgba(0,0,0,0.11)
}
                &__logo{
                    width: 100px;
                    margin: 0 auto;
                    margin-bottom: 2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;
                    img{
                        width: 100%;
                        height: 100px;
                    }

                    h2{
                        font-size: 30px;
                    }
                }
                
                &__description{
                    padding: 0 1.4rem;
                    font-size: 20px;
                    color: gray;
                }
                
                padding: 4rem 2rem;
            }
        }
    }
}